//#region Imports!
import PropTypes from "prop-types";
import React from "react";
//The images.
import Consumables from "./../assets/Consumables.gif";
import Tasks from "./../assets/Tasks.gif";
import DrillingDetails from "./../assets/DrillingDetails.gif";
import Operator from "./../assets/Operator.png";
import ShiftComments from "./../assets/ShiftComments.gif";

//Icons.
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";

//For the grid.
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEditInputCell,
  GridEditSingleSelectCell,
  GridEditSingleSelectCellProps,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarContainer,
  useGridApiContext,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import { Button, TextareaAutosize } from "@mui/material";

//Tools.
import renameKey from "../functions/renameKey";
import isWhiteSpaceOrEmpty from "./../functions/isWhiteSpaceOrEmpty";
import checkNewPlodData from "./../functions/checkNewPlodData";

//Types.
import { MiningCompany } from "./../types/miningCompanyType";
import { Contract } from "./../types/contractType";
import { DrillingProgram } from "./../types/drillingProgramType";

//Components.
import NewPlodBox from "../components/newPlodBox";
import Swal from "sweetalert2";
import { Dayjs } from "dayjs";
import { truncateSync } from "fs";
import Api from "../api/api";

//#endregion

//#region TYPES
type OperatorRow = {
  ID: number;
  ID_EA_Personnel: number;
  ID_EA_PersonnelType: number;
  hours: number;
  comment: string;
};

type OperatorOptions = {
  value: number;
  label: string;
  ID_EA_PersonnelType: number;
};

type OperatorTypeOption = {
  value: number;
  label: string;
};
type DrillingDetailsRow = {
  ID: number;
  hole: string;
  from: number;
  to: number;
  total: number;
  totalRecovered: number;
  hours: number;
  bit: string;
  bitSize: number;
  HoleText: string;
};

type BitSizeOptions = {
  value: number;
  label: string;
};

type TaskRow = {
  ID: number;
  task: number;
  hours: number;
  charge: string;
  comment: string;
};

type TaskOption = {
  value: number;
  label: string;
};
type ChargesOption = {
  value: number;
  label: string;
};

type ConsumableRow = {
  ID: number;
  consumable: number;
  quantity: number;
  charge: boolean;
};
type OneOffChargeRow = {
  ID: number;
  oneoffcharge: number;
  quantity: number;
  comment: string;
};

type ConsumableOption = {
  value: number;
  label: string;
};

type OneOffChargeOption = {
  value: number;
  label: string;
};

type UpdatePlodData = {
  Operators: OperatorRow[];
  DrillingDetails: DrillingDetailsRow[];
  Tasks: TaskRow[];
  Consumables: ConsumableRow[];
  ShiftComments: string;
  OneOffCharges: OneOffChargeRow[];

};
type NewPlodData = {
  Date: string;
  Shift: string;
  Contract: string;
  MiningCompany: string;
  Site: string;
  Rig: string;
  Operators: OperatorRow[];
  DrillingProgram: string;
  Status: string;
  DrillingDetails: DrillingDetailsRow[];
  Tasks: TaskRow[];
  Consumables: ConsumableRow[];
  ShiftComments: string;
  OneOffCharges: OneOffChargeRow[];
};
//#endregion

const PlodDetails = (props: {
  save: boolean;
  plod_id: string;
  drillingProgramSelectorID: string;
  contractSelectorID: string;
  miningCompanySelectorID: string;
  statusFilter: string;
  shiftFilter: string;
  dateValue: Dayjs;
  rigFilter: string;
  siteFilter: string;
  shiftID: string;
}) => {
  if (props.plod_id == "" || props.plod_id == null) {
    window.location.replace("https://login.redochre.cloud/");
  }
  const [operatorRows, setOperatorRows] = React.useState<OperatorRow[]>([]);
  const [drillingDetailsRows, setDrillingDetailsRows] = React.useState<
    DrillingDetailsRow[]
  >([]);
  const [taskRows, setTaskRows] = React.useState<TaskRow[]>([]);
  const [consumableRows, setConsumableRows] = React.useState<ConsumableRow[]>(
    []
  );
  const [oneOffChargeRows, setOneOffChargeRows] = React.useState<OneOffChargeRow[]>(
    []
  );


  const [shiftComments, setShiftComments] = React.useState<string>("");
  const [operatorRowModesModel, setOperatorRowModesModel] =
    React.useState<GridRowModesModel>({});
  const [operatorOptions, setOperatorOptions] = React.useState<
    OperatorOptions[]
  >([]);
  const [operatorTypeOptions, setOperatorTypeOptions] = React.useState<
    OperatorTypeOption[]
  >([]);
  const [drillingDetailsRowModesModel, setDrillingDetailsRowModesModel] =
    React.useState<GridRowModesModel>({});
  const [bitSizeOptions, setBitSizeOptions] = React.useState<BitSizeOptions[]>(
    []
  );
  const [taskRowModesModel, setTaskRowModesModel] =
    React.useState<GridRowModesModel>({});
  const [taskOptions, setTaskOptions] = React.useState<TaskOption[]>([]);
  const [consumableRowModesModel, setConsumableRowModesModel] =
    React.useState<GridRowModesModel>({});
  const [consumableOptions, setConsumableOptions] = React.useState<
    ConsumableOption[]
  >([]);

  const [oneOffChargeRowModesModel, setOneOffChargeRowModesModel] =
    React.useState<GridRowModesModel>({});
  const [oneOffChargeOptions, setOneOffChargeOptions] = React.useState<
    OneOffChargeOption[]
  >([]);


  const [charges, setCharges] = React.useState<ChargesOption[]>([]);



  React.useEffect(() => {
    setOperatorRows([]);
    setDrillingDetailsRows([]);
    setTaskRows([]);
    setConsumableRows([]);
    setShiftComments("");
  }, [props.drillingProgramSelectorID]);

  React.useEffect(() => {
    const fetchPopulatingData = async () => {
      if (
        props.miningCompanySelectorID != "0" &&
        props.contractSelectorID != "0" &&
        props.drillingProgramSelectorID != "0" &&
        props.shiftID != null &&
        props.shiftID != "0"
      ) {
        var requestOptions = {
          method: "POST",
        };
        //Fetch basic data.
        Api.post(
          `/api/Plods/ID_${props.plod_id}/MiningCompany/ID_${props.miningCompanySelectorID}/Contract/ID_${props.contractSelectorID}/DrillingProgram/ID_${props.drillingProgramSelectorID}/Shift/ID_${props.shiftID}/`,
          requestOptions
        )
          .then(({ data }) => {
            setShiftComments(data.ShiftComment);
          })
          .catch((err) => console.log(err.message));
        await Api.post(
          `/api/Plods/ID_${props.plod_id}/MiningCompany/ID_${props.miningCompanySelectorID}/Contract/ID_${props.contractSelectorID}/DrillingProgram/ID_${props.drillingProgramSelectorID}/Shift/ID_${props.shiftID}/~ShiftDrilling_GetValues`,
          requestOptions
        )
          .then(({ data }) => {
            //Needs text pointed to it.
            data.forEach((obj: any) => renameKey(obj, "ID_SH_Drilling", "ID"));
            data.forEach((obj: any) => renameKey(obj, "HoleText", "hole"));
            data.forEach((obj: any) => renameKey(obj, "From", "from"));
            data.forEach((obj: any) => renameKey(obj, "To", "to"));
            data.forEach((obj: any) =>
              renameKey(obj, "TotalRecovered", "totalRecovered")
            );

            data.forEach((obj: any) => renameKey(obj, "Hours", "hours"));
            //Needs text pointed to it.
            data.forEach((obj: any) => renameKey(obj, "BitSerial", "bit"));
            data.forEach((obj: any) =>
              renameKey(obj, "ID_EA_BitSize", "bitSize")
            );

            setDrillingDetailsRows(data);
          })
          .catch((err) => console.log(err.message));
        //Fetch the tasks
        await Api.post(
          `/api/Plods/ID_${props.plod_id}/MiningCompany/ID_${props.miningCompanySelectorID}/Contract/ID_${props.contractSelectorID}/DrillingProgram/ID_${props.drillingProgramSelectorID}/Shift/ID_${props.shiftID}/ShiftTasks`,
          requestOptions
        )
          .then(({ data }) => {
            data.forEach((obj: any) => renameKey(obj, "ID_EA_Task", "tasks"));
            data.forEach((obj: any) => renameKey(obj, "Hours", "hours"));
            data.forEach((obj: any) =>
              renameKey(obj, "O_Chargetype", "charge")
            );
            data.forEach((obj: any) => renameKey(obj, "Comment", "comment"));

            setTaskRows(data);
          })
          .catch((err) => console.log(err.message));
        //Fetch the Consumables
        await Api.post(
          `/api/Plods/ID_${props.plod_id}/MiningCompany/ID_${props.miningCompanySelectorID}/Contract/ID_${props.contractSelectorID}/DrillingProgram/ID_${props.drillingProgramSelectorID}/Shift/ID_${props.shiftID}/Consumables`,
          requestOptions
        )
          .then(({ data }) => {
            data.forEach((obj: any) =>
              renameKey(obj, "ID_EA_Consumable", "consumable")
            );

            data.forEach((obj: any) => renameKey(obj, "Quantity", "quantity"));
            data.forEach((obj: any) => renameKey(obj, "Charge", "charge"));

            setConsumableRows(data);
          })
          .catch((err) => console.log(err?.message));

        await Api.post(
          `/api/Plods/ID_${props.plod_id}/MiningCompany/ID_${props.miningCompanySelectorID}/Contract/ID_${props.contractSelectorID}/DrillingProgram/ID_${props.drillingProgramSelectorID}/Shift/ID_${props.shiftID}/ShiftOneOffCharges`,
          requestOptions
        )
          .then(({ data }) => {
            data.forEach((obj: any) =>
              renameKey(obj, "ID_EDP_OneOffCharges", "oneoffcharge")
            );

            data.forEach((obj: any) => renameKey(obj, "Quantity", "quantity"));
            data.forEach((obj: any) => renameKey(obj, "Comments", "comment"));
            console.log(data);
            setOneOffChargeRows(data);
          })
          .catch((err) => console.log(err?.message));

        //Fetch the Operators
        await Api.post(
          `/api/Plods/ID_${props.plod_id}/MiningCompany/ID_${props.miningCompanySelectorID}/Contract/ID_${props.contractSelectorID}/DrillingProgram/ID_${props.drillingProgramSelectorID}/Shift/ID_${props.shiftID}/Personnel`,
          requestOptions
        )

          .then(({ data }) => {
            data.forEach((obj: any) => renameKey(obj, "Hours", "hours"));
            data.forEach((obj: any) => renameKey(obj, "Comment", "comments"));

            setOperatorRows(data);
          })
          .catch((err) => console.log(err.message));
      }
    };
    fetchPopulatingData();
  }, [props.shiftID, props.drillingProgramSelectorID]);

  //#region Operators

  const handleOperatorEditClick = (ID: GridRowId) => () => {
    setOperatorRowModesModel({
      ...operatorRowModesModel,
      [ID]: { mode: GridRowModes.Edit },
    });
  };

  const handleOperatorSaveClick = (ID: GridRowId) => () => {
    setOperatorRowModesModel({
      ...operatorRowModesModel,
      [ID]: { mode: GridRowModes.View },
    });
  };

  const handleOperatorDeleteClick = (ID: GridRowId) => () => {
    setOperatorRows(operatorRows.filter((row: OperatorRow) => row.ID !== ID));
  };

  const handleOperatorCancelClick = (ID: GridRowId) => () => {
    setOperatorRowModesModel({
      ...operatorRowModesModel,
      [ID]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    const editedRow: any = operatorRows.find(
      (row: OperatorRow) => row.ID === ID
    );

    if (editedRow!.isNew) {
      setOperatorRows(operatorRows.filter((row: OperatorRow) => row.ID !== ID));
    }
  };

  function CustomTypeEditComponent(props: GridEditSingleSelectCellProps) {
    const apiRef = useGridApiContext();

    const handleValueChange = async (e: any) => {
      const editedRow: OperatorOptions | undefined = operatorOptions.find(
        (row: OperatorOptions) => row.value === e.target.value
      );
      const personnelType: number = editedRow!.ID_EA_PersonnelType;

      await apiRef.current.setEditCellValue({
        id: props.id,
        field: "ID_EA_PersonnelType",
        value: personnelType,
      });
    };

    return (
      <GridEditSingleSelectCell onValueChange={handleValueChange} {...props} />
    );
  }

  const operatorColumns: GridColDef<any>[] = [
    {
      field: "ID_EA_Personnel",
      headerName: "Operator",
      flex: 2,
      editable: true,
      type: "singleSelect",
      valueOptions: operatorOptions,
      renderEditCell: (params: GridEditSingleSelectCellProps) => (
        <CustomTypeEditComponent
          {...params}
          operatorTypeOptions={operatorTypeOptions}
        />
      ),
    },
    {
      field: "ID_EA_PersonnelType",
      headerName: "Type Override",
      flex: 2,
      editable: true,
      type: "singleSelect",
      valueOptions: operatorTypeOptions,
    },
    {
      field: "hours",
      headerName: "Hours",
      flex: 1,
      editable: true,
      type: "number",
    },
    {
      field: "comments",
      headerName: "Comments",
      flex: 5,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode =
          operatorRowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleOperatorSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleOperatorCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleOperatorEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleOperatorDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  const processOperatorRowUpdate = (newRow: GridRowModel) => {
    var updatedRow = { ...newRow, isNew: false };
    //Checks that a personnel has been selected.
    if (newRow.ID_EA_Personnel == 0) {
      Swal.fire("You must select an operator", "", "error");
      return {};
    }
    //Checks that the hours are not less than zero.
    else if (newRow.hours < 0) {
      Swal.fire("Hours cannot be negative.", "", "error");
      return {};
    }
    //Returns like normal if all other conditions met.
    else {
      setOperatorRows(
        operatorRows.map((row: any) =>
          row.ID === newRow.ID ? updatedRow : row
        )
      );
      return updatedRow;
    }
  };

  const handleOperatorRowModesModelChange = (
    newRowModesModel: GridRowModesModel
  ) => {
    setOperatorRowModesModel(newRowModesModel);
  };

  interface EditOperatorToolbarProps {
    setOperatorRows: (
      newOperatorRows: (oldRows: GridRowsProp) => GridRowsProp
    ) => void;
    setOperatorRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
  }

  function EditOperatorToolbar(props2: EditOperatorToolbarProps) {
    const { setOperatorRows, setOperatorRowModesModel } = props2;

    const handleOperatorsClick = () => {
      const ID = randomId();
      setOperatorRows((oldRows: any) => [
        ...oldRows,
        {
          ID,
          ID_EA_Personnel: 0,
          ID_EA_PersonnelType: 0,
          hours: 0,
          comment: "",
          isNew: true,
        },
      ]);
      setOperatorRowModesModel((oldModel: any) => ({
        ...oldModel,
        [ID]: { mode: GridRowModes.Edit, fieldToFocus: "ID_EA_Personnel" },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button
          style={{ marginLeft: "auto", marginRight: 0 }}
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOperatorsClick}
          disabled={
            props.shiftID == "0" &&
            props.drillingProgramSelectorID == "SelectDrillingProgram"
          }
        >
          Add Operators
        </Button>
      </GridToolbarContainer>
    );
  }

  //#endregion

  //#region Drilling Details
  const handleDrillingDetailsEditClick = (ID: GridRowId) => () => {
    setDrillingDetailsRowModesModel({
      ...drillingDetailsRowModesModel,
      [ID]: { mode: GridRowModes.Edit },
    });
  };

  const handleDrillingDetailsSaveClick = (ID: GridRowId) => () => {
    setDrillingDetailsRowModesModel({
      ...drillingDetailsRowModesModel,
      [ID]: { mode: GridRowModes.View },
    });
  };

  const handleDrillingDetailsDeleteClick = (ID: GridRowId) => () => {
    setDrillingDetailsRows(
      drillingDetailsRows.filter((row: DrillingDetailsRow) => row.ID !== ID)
    );
  };

  const handleDrillingDetailsCancelClick = (ID: GridRowId) => () => {
    setDrillingDetailsRowModesModel({
      ...drillingDetailsRowModesModel,
      [ID]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    const editedRow: any = drillingDetailsRows.find(
      (row: DrillingDetailsRow) => row.ID === ID
    );

    if (editedRow!.isNew) {
      setDrillingDetailsRows(
        drillingDetailsRows.filter((row: DrillingDetailsRow) => row.ID !== ID)
      );
    }
  };

  const calculateTotal = (from: any, to: any) => {
    return parseInt(to) - parseInt(from);
  };
  const DrillingDetailsCellEditor = (props: any) => {
    const { ID, field, value, hasFocus } = props;
    const apiRef: any = useGridApiContext();

    const handleValueChange = (event: any) => {
      const newValue = event.target.value;
      if (field === "from" || field === "to") {
        const updatedDetails = drillingDetailsRows.map((row) => {
          if (row.ID === ID) {
            const updatedRow = {
              ...row,
              [field]: newValue,
              total:
                field === "from"
                  ? calculateTotal(newValue, row.to)
                  : calculateTotal(row.from, newValue),
            };
            return updatedRow;
          }
          return row;
        });
        setDrillingDetailsRows(updatedDetails);
      }
    };

    return (
      <GridEditInputCell
        value={value}
        onValueChange={handleValueChange}
        {...props}
      />
    );
  };
  const drillingDetailsColumns: GridColDef<any>[] = [
    {
      field: "hole",
      headerName: "Hole",
      flex: 2,
      editable: true,
    },
    {
      field: "from",
      headerName: "From",
      flex: 1,
      editable: true,
      type: "number",
      renderEditCell: DrillingDetailsCellEditor,
    },
    {
      field: "to",
      headerName: "To",
      flex: 1,
      editable: true,
      type: "number",
      renderEditCell: DrillingDetailsCellEditor,
    },
    {
      field: "total",
      headerName: "Total",
      flex: 1,
      editable: false,
      type: "number",
      valueGetter: (params) => params.row.to - params.row.from,
    },
    {
      field: "totalRecovered",
      headerName: "Total Recovered",
      flex: 1,
      editable: true,
      type: "number",
    },
    {
      field: "hours",
      headerName: "Hours",
      flex: 1,
      editable: true,
      type: "number",
    },
    {
      field: "bit",
      headerName: "Bit #",
      flex: 1,
      editable: true,
    },
    {
      field: "bitSize",
      headerName: "Bit Size",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: bitSizeOptions,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode =
          drillingDetailsRowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleDrillingDetailsSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleDrillingDetailsCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleDrillingDetailsEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDrillingDetailsDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const processDrillingDetailsRowUpdate = (newRow: GridRowModel) => {
    var updatedRow = { ...newRow, isNew: false };

    //Checks that a data has been inputed.
    if (isWhiteSpaceOrEmpty(newRow.hole)) {
      Swal.fire("You must input a hole name.", "", "error");
      return {};
    } else if (isWhiteSpaceOrEmpty(newRow.bit)) {
      Swal.fire("You must input a bit name.", "", "error");
      return {};
    } else if (newRow.bitSize == 0) {
      Swal.fire("You must select a bit size.", "", "error");
      return {};
    }

    //Checks that the hours are not less than zero.
    else if (newRow.from < 0) {
      Swal.fire("From cannot be negative.", "", "error");
      return {};
    } else if (newRow.to < 0) {
      Swal.fire("To cannot be negative.", "", "error");
      return {};
    }

    //Checks that from is smaller than to.
    else if (newRow.from > newRow.to) {
      Swal.fire("From must be smaller than to.", "", "error");
      return {};
    }

    //Returns like normal if all other conditions met.
    else {
      setDrillingDetailsRows(
        drillingDetailsRows.map((row: any) =>
          row.ID === newRow.ID ? updatedRow : row
        )
      );
      return updatedRow;
    }
  };

  const handleDrillingDetailsRowModesModelChange = (
    newRowModesModel: GridRowModesModel
  ) => {
    // Update the state or data with the new details
    setDrillingDetailsRowModesModel(newRowModesModel);
  };

  interface EditDrillingDetailsToolbarProps {
    setDrillingDetailsRows: (
      newDrillingDetailsRows: (oldRows: GridRowsProp) => GridRowsProp
    ) => void;
    setDrillingDetailsRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
  }

  function EditDrillingDetailsToolbar(props2: EditDrillingDetailsToolbarProps) {
    const { setDrillingDetailsRows, setDrillingDetailsRowModesModel } = props2;

    const handleDrillingDetailsClick = () => {
      const ID = randomId();
      setDrillingDetailsRows((oldRows: any) => [
        ...oldRows,
        {
          ID,
          hole: "",
          from: 0,
          to: 0,
          total: 0,
          totalRecovered: 0,
          hours: 0,
          bit: "",
          bitSize: 0,
          isNew: true,
        },
      ]);
      setDrillingDetailsRowModesModel((oldModel: any) => ({
        ...oldModel,
        [ID]: { mode: GridRowModes.Edit, fieldToFocus: "hole" },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button
          style={{ marginLeft: "auto", marginRight: 0 }}
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleDrillingDetailsClick}
          disabled={
            props.shiftID == "0" &&
            props.drillingProgramSelectorID == "SelectDrillingProgram"
          }
        >
          Add Drilling Details
        </Button>
      </GridToolbarContainer>
    );
  }
  //#endregion

  //#region Tasks
  const handleTaskEditClick = (ID: GridRowId) => () => {
    setTaskRowModesModel({
      ...taskRowModesModel,
      [ID]: { mode: GridRowModes.Edit },
    });
  };

  const handleTaskSaveClick = (ID: GridRowId) => () => {
    setTaskRowModesModel({
      ...taskRowModesModel,
      [ID]: { mode: GridRowModes.View },
    });
  };

  const handleTaskDeleteClick = (ID: GridRowId) => () => {
    setTaskRows(taskRows.filter((row: TaskRow) => row.ID !== ID));
  };

  const handleTaskCancelClick = (ID: GridRowId) => () => {
    setTaskRowModesModel({
      ...taskRowModesModel,
      [ID]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    const editedRow: any = taskRows.find((row: TaskRow) => row.ID === ID);

    if (editedRow!.isNew) {
      setTaskRows(taskRows.filter((row: TaskRow) => row.ID !== ID));
    }
  };
  const taskColumns: GridColDef<any>[] = [
    {
      field: "tasks",
      headerName: "Tasks",
      flex: 2,
      editable: true,
      type: "singleSelect",
      valueOptions: taskOptions,
    },
    {
      field: "hours",
      headerName: "Hours",
      flex: 1,
      editable: true,
      type: "number",
    },
    {
      field: "charge",
      headerName: "Charge",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: charges
    },
    {
      field: "comment",
      headerName: "Comment",
      flex: 4,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = taskRowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleTaskSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleTaskCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleTaskEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleTaskDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  const processTaskRowUpdate = (newRow: GridRowModel) => {
    var updatedRow = { ...newRow, isNew: false };
    //Checks that a personnel has been selected.
    if (newRow.tasks == 0) {
      Swal.fire("You must select a task", "", "error");
      return {};
    }
    if (newRow.charge == "") {
      Swal.fire("You must select a charge", "", "error");
      return {};
    }
    //Checks that the hours are not less than zero.
    else if (newRow.hours < 0) {
      Swal.fire("Hours cannot be negative.", "", "error");
      return {};
    }
    //Returns like normal if all other conditions met.
    else {
      setTaskRows(
        taskRows.map((row: any) => (row.ID === newRow.ID ? updatedRow : row))
      );
      return updatedRow;
    }
  };

  const handleTaskRowModesModelChange = (
    newRowModesModel: GridRowModesModel
  ) => {
    setTaskRowModesModel(newRowModesModel);
  };

  interface EditConsumableToolbarProps {
    setTaskRows: (newTaskRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setTaskRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
  }

  function EditTaskToolbar(props2: EditConsumableToolbarProps) {
    const { setTaskRows, setTaskRowModesModel } = props2;

    const handleTaskClick = () => {
      const ID = randomId();
      setTaskRows((oldRows: any) => [
        ...oldRows,
        {
          ID,
          tasks: 0,
          hours: 0,
          charge: "",
          comment: "",
          isNew: true,
        },
      ]);
      setTaskRowModesModel((oldModel: any) => ({
        ...oldModel,
        [ID]: { mode: GridRowModes.Edit, fieldToFocus: "tasks" },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button
          style={{ marginLeft: "auto", marginRight: 0 }}
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleTaskClick}
          disabled={
            props.shiftID == "0" &&
            props.drillingProgramSelectorID == "SelectDrillingProgram"
          }
        >
          Add Tasks
        </Button>
      </GridToolbarContainer>
    );
  }
  //#endregion

  //#region Consumables
  const handleConsumableEditClick = (ID: GridRowId) => () => {
    setConsumableRowModesModel({
      ...consumableRowModesModel,
      [ID]: { mode: GridRowModes.Edit },
    });
  };

  const handleConsumableSaveClick = (ID: GridRowId) => () => {
    setConsumableRowModesModel({
      ...consumableRowModesModel,
      [ID]: { mode: GridRowModes.View },
    });
  };

  const handleConsumableDeleteClick = (ID: GridRowId) => () => {
    setConsumableRows(
      consumableRows.filter((row: ConsumableRow) => row.ID !== ID)
    );
  };

  const handleConsumableCancelClick = (ID: GridRowId) => () => {
    setConsumableRowModesModel({
      ...consumableRowModesModel,
      [ID]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    const editedRow: any = consumableRows.find(
      (row: ConsumableRow) => row.ID === ID
    );

    if (editedRow!.isNew) {
      setConsumableRows(
        consumableRows.filter((row: ConsumableRow) => row.ID !== ID)
      );
    }
  };
  const consumableColumns: GridColDef<any>[] = [
    {
      field: "consumable",
      headerName: "Consumable",
      flex: 4,
      editable: true,
      type: "singleSelect",
      valueOptions: consumableOptions,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
      editable: true,
      type: "number",
    },
    {
      field: "charge",
      headerName: "Charge?",
      flex: 1,
      editable: true,
      type: "boolean",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode =
          consumableRowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleConsumableSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleConsumableCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleConsumableEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleConsumableDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  const processConsumableRowUpdate = (newRow: GridRowModel) => {
    var updatedRow = { ...newRow, isNew: false };
    //Checks that a personnel has been selected.
    if (newRow.consumable == 0) {
      Swal.fire("You must select a task", "", "error");
      return {};
    }
    //Checks that the hours are not less than zero.
    else if (newRow.quantity < 0) {
      Swal.fire("Hours cannot be negative.", "", "error");
      return {};
    }
    //Returns like normal if all other conditions met.
    else {
      setConsumableRows(
        consumableRows.map((row: any) =>
          row.ID === newRow.ID ? updatedRow : row
        )
      );
      return updatedRow;
    }
  };

  const handleConsumableRowModesModelChange = (
    newRowModesModel: GridRowModesModel
  ) => {
    setConsumableRowModesModel(newRowModesModel);
  };

  interface EditConsumableToolbarProps {
    setConsumableRows: (
      newConsumableRows: (oldRows: GridRowsProp) => GridRowsProp
    ) => void;
    setConsumableRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
  }

  function EditConsumableToolbar(props2: EditConsumableToolbarProps) {
    const { setConsumableRows, setConsumableRowModesModel } = props2;

    const handleConsumableClick = () => {
      const ID = randomId();
      setConsumableRows((oldRows: any) => [
        ...oldRows,
        {
          ID,
          consumable: 0,
          hours: 0,
          charge: false,
          comment: "",
          isNew: true,
        },
      ]);
      setConsumableRowModesModel((oldModel: any) => ({
        ...oldModel,
        [ID]: { mode: GridRowModes.Edit, fieldToFocus: "consumable" },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button
          style={{ marginLeft: "auto", marginRight: 0 }}
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleConsumableClick}
          disabled={
            props.shiftID == "0" &&
            props.drillingProgramSelectorID == "SelectDrillingProgram"
          }
        >
          Add Consumables
        </Button>
      </GridToolbarContainer>
    );
  }
  //#endregion

  //#region One Off Charges
  const handleOneOffChargeEditClick = (ID: GridRowId) => () => {
    setOneOffChargeRowModesModel({
      ...oneOffChargeRowModesModel,
      [ID]: { mode: GridRowModes.Edit },
    });
  };

  const handleOneOffChargeSaveClick = (ID: GridRowId) => () => {
    setOneOffChargeRowModesModel({
      ...oneOffChargeRowModesModel,
      [ID]: { mode: GridRowModes.View },
    });
  };

  const handleOneOffChargeDeleteClick = (ID: GridRowId) => () => {
    setOneOffChargeRows(
      oneOffChargeRows.filter((row: OneOffChargeRow) => row.ID !== ID)
    );
  };

  const handleOneOffChargeCancelClick = (ID: GridRowId) => () => {
    setOneOffChargeRowModesModel({
      ...oneOffChargeRowModesModel,
      [ID]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    const editedRow: any = oneOffChargeRows.find(
      (row: OneOffChargeRow) => row.ID === ID
    );

    if (editedRow!.isNew) {
      setOneOffChargeRows(
        oneOffChargeRows.filter((row: OneOffChargeRow) => row.ID !== ID)
      );
    }
  };
  const oneOffChargeColumns: GridColDef<any>[] = [
    {
      field: "oneoffcharge",
      headerName: "One Off Charge",
      flex: 4,
      editable: true,
      type: "singleSelect",
      valueOptions: oneOffChargeOptions,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
      editable: true,
      type: "number",
    },
    {
      field: "comment",
      headerName: "Comments",
      flex: 2.5,
      editable: true,
      type: "string",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode =
          oneOffChargeRowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleOneOffChargeSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleOneOffChargeCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleOneOffChargeEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleOneOffChargeDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  const processOneOffChargeRowUpdate = (newRow: GridRowModel) => {
    var updatedRow = { ...newRow, isNew: false };
    //Checks that a personnel has been selected.
    if (newRow.consumable == 0) {
      Swal.fire("You must select a task", "", "error");
      return {};
    }
    //Checks that the hours are not less than zero.
    else if (newRow.quantity < 0) {
      Swal.fire("Quantity cannot be negative.", "", "error");
      return {};
    }
    //Returns like normal if all other conditions met.
    else {
      setOneOffChargeRows(
        oneOffChargeRows.map((row: any) =>
          row.ID === newRow.ID ? updatedRow : row
        )
      );
      return updatedRow;
    }
  };

  const handleOneOffChargeRowModesModelChange = (
    newRowModesModel: GridRowModesModel
  ) => {
    setOneOffChargeRowModesModel(newRowModesModel);
  };

  interface EditOneOffChargeToolbarProps {
    setOneOffChargeRows: (
      newOneOffChargeRows: (oldRows: GridRowsProp) => GridRowsProp
    ) => void;
    setOneOffChargeRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
  }

  function EditOneOffChargeToolbar(props2: EditOneOffChargeToolbarProps) {
    const { setOneOffChargeRows, setOneOffChargeRowModesModel } = props2;

    const handleOneOffChargeClick = () => {
      const ID = randomId();
      setOneOffChargeRows((oldRows: any) => [
        ...oldRows,
        {
          ID,
          oneoffcharge: 0,
          quantity: 1,
          isNew: true,
        },
      ]);
      setOneOffChargeRowModesModel((oldModel: any) => ({
        ...oldModel,
        [ID]: { mode: GridRowModes.Edit, fieldToFocus: "oneoffcharge" },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button
          style={{ marginLeft: "auto", marginRight: 0 }}
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOneOffChargeClick}
          disabled={
            props.shiftID == "0" &&
            props.drillingProgramSelectorID == "SelectDrillingProgram"
          }
        >
          Add One Off Charge
        </Button>
      </GridToolbarContainer>
    );
  }

//for Charges Dropdown
React.useEffect(() => {
 
  const fetchData = async () => {
    if (props.shiftID != "0" || props.drillingProgramSelectorID != "0") {
      Api.post(
        `/api/Plods/ID_${props.plod_id}/MiningCompany/ID_${props.miningCompanySelectorID}/Contract/ID_${props.contractSelectorID}/DrillingProgram/ID_${props.drillingProgramSelectorID}/TaskChargeType`
      )
        .then(({ data }) => {
          try {
            data.forEach((obj: any) => renameKey(obj, "ID", "value"));
            data.forEach((obj: any) => renameKey(obj, "ChargeType", "label"));
            setCharges(data);
          } catch {
            setCharges([]);
          }
        })
        .catch((e) => console.warn(e.message));
    } else {
      setCharges([]);
    }
  };


  fetchData();
}, [props.shiftID, props.drillingProgramSelectorID]); // Dependency array to refetch when these props change









  //#endregion

  //#endregion

  //#region populate data.
  React.useEffect(() => {
    const fetchData = async () => {
      if (props.shiftID != "0" || props.drillingProgramSelectorID != "0") {
        await Api.post(
          `/api/Plods/ID_${props.plod_id}/MiningCompany/ID_${props.miningCompanySelectorID}/Contract/ID_${props.contractSelectorID}/DrillingProgram/ID_${props.drillingProgramSelectorID}/~DPConsumable_GetValues`
        )
          .then(({ data }) => {
            try {
              data.forEach((obj: any) => renameKey(obj, "ID", "value"));
              data.forEach((obj: any) =>
                renameKey(obj, "ConsumableName", "label")
              );
              setConsumableOptions(data);
            } catch {
              setConsumableOptions([]);
            }
          })
          .catch((e) => console.log(e?.message));
      } else {
        setConsumableOptions([]);
      }
    };
    fetchData();
  }, [props.shiftID, props.drillingProgramSelectorID]);


  React.useEffect(() => {
    const fetchData = async () => {
      if (props.shiftID != "0" || props.drillingProgramSelectorID != "0") {
        await Api.post(
          `/api/Plods/ID_${props.plod_id}/MiningCompany/ID_${props.miningCompanySelectorID}/Contract/ID_${props.contractSelectorID}/DrillingProgram/ID_${props.drillingProgramSelectorID}/OneOffCharges`
        )
          .then(({ data }) => {
            try {
              data.forEach((obj: any) => renameKey(obj, "ID", "value"));
              data.forEach((obj: any) =>
                renameKey(obj, "One_Off_Charge_Name", "label")
              );
              setOneOffChargeOptions(data);
            } catch {
              setOneOffChargeOptions([]);
            }
          })
          .catch((e) => console.log(e?.message));
      } else {
        setOneOffChargeOptions([]);
      }
    };
    fetchData();
  }, [props.shiftID, props.drillingProgramSelectorID]);
  


  React.useEffect(() => {
    const fetchData = async () => {
      if (props.shiftID != "0" || props.drillingProgramSelectorID != "0") {
        Api.post(
          `/api/Plods/ID_${props.plod_id}/MiningCompany/ID_${props.miningCompanySelectorID}/Contract/ID_${props.contractSelectorID}/DrillingProgram/ID_${props.drillingProgramSelectorID}/~DPTask_GetValues`
        )
          .then(({ data }) => {
            try {
              data.forEach((obj: any) => renameKey(obj, "TaskID", "value"));
              data.forEach((obj: any) => renameKey(obj, "TaskName", "label"));
              setTaskOptions(data);
            } catch {
              setTaskOptions([]);
            }
          })
          .catch((e) => console.warn(e.message));
      } else {
        setTaskOptions([]);
      }
    };
    fetchData();
  }, [props.shiftID, props.drillingProgramSelectorID]);

  React.useEffect(() => {
    const fetchData = async () => {
      await Api.post(`/api/Plods/ID_${props.plod_id}/BitSizes`)
        .then(({ data }) => {
          data.forEach((obj: any) => renameKey(obj, "ID", "value"));
          data.forEach((obj: any) => renameKey(obj, "Size", "label"));
          setBitSizeOptions(data);
        })
        .catch((e) => console.log(e?.message));
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      await Api.post(`/api/Plods/ID_${props.plod_id}/PersonnelType`).then(
        ({ data }) => {
          data.forEach((obj: any) => renameKey(obj, "ID", "value"));
          data.forEach((obj: any) =>
            renameKey(obj, "One_Off_Charge_Name", "label")
          );
          setOperatorTypeOptions(data);
        }
      );
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      await Api.post(`/api/Plods/ID_${props.plod_id}/PersonnelType`).then(
        ({ data }) => {
          data.forEach((obj: any) => renameKey(obj, "ID", "value"));
          data.forEach((obj: any) =>
            renameKey(obj, "PersonnelTypeName", "label")
          );
          setOperatorTypeOptions(data);
        }
      );
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      if (props.drillingProgramSelectorID != "0") {
        await Api.post(
          `/api/Plods/ID_${props.plod_id}/MiningCompany/ID_${props.miningCompanySelectorID}/Contract/ID_${props.contractSelectorID}/DrillingProgram/ID_${props.drillingProgramSelectorID}/~DPPersonnel_GetValues`
        )
          .then(({ data }) => {
            try {
              data.forEach((obj: any) =>
                renameKey(obj, "ID_EA_Personnel", "value")
              );
              data.forEach(
                (obj: any) =>
                  (obj["label"] = obj["FirstName"] + " " + obj["LastName"])
              );
              setOperatorOptions(data);
            } catch {
              setOperatorOptions([]);
            }
          })
          .catch((e) => console.warn(e?.message));
      } else {
        setOperatorOptions([]);
      }
    };
    fetchData();
  }, [props.shiftID, props.drillingProgramSelectorID]);
  //#endregion

  const handleSubmitButtonClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (props.save) {
      const dataJSON: NewPlodData = {
        Date: props.dateValue?.format("MM/DD/YY").toString() || "",
        Shift: props.shiftFilter,
        Site: props.siteFilter,
        Contract: props.contractSelectorID,
        Rig: props.rigFilter,
        MiningCompany: props.miningCompanySelectorID,
        Operators: operatorRows,
        DrillingProgram: props.drillingProgramSelectorID,
        Status: props.statusFilter,
        DrillingDetails: drillingDetailsRows,
        Tasks: taskRows,
        Consumables: consumableRows,
        ShiftComments: shiftComments,
        OneOffCharges: oneOffChargeRows
      };

      const data: string = JSON.stringify(dataJSON);

      var result = checkNewPlodData(
        operatorRows,
        drillingDetailsRows,
        taskRows,
        consumableRows
      );

      if (result == true) {
        await Api.post(
          `/api/Plods/ID_${props.plod_id}/MiningCompany/ID_${props.miningCompanySelectorID}/Contract/ID_${props.contractSelectorID}/DrillingProgram/ID_${props.drillingProgramSelectorID}/~AddEntireShift`,
          dataJSON
        )
          .then((response) => {
            if (response.status == 400) {
              Swal.fire("failed to save data.", "", "error");
            } else {
              Swal.fire("Successfully Added Data.", "", "success");
            }
          })
          .catch((e) => Swal.fire(`An error occured.\n${e}`, "", "error"));
      } else {
        Swal.fire(result, "", "error");
      }
    } else {
      const dataJSON: UpdatePlodData = {
        Operators: operatorRows,
        DrillingDetails: drillingDetailsRows,
        Tasks: taskRows,
        Consumables: consumableRows,
        ShiftComments: shiftComments,
        OneOffCharges: oneOffChargeRows
      };

      const data: string = JSON.stringify(dataJSON);

      var result = checkNewPlodData(
        operatorRows,
        drillingDetailsRows,
        taskRows,
        consumableRows
      );

      if (result == true) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "*/*");
        var requestOptions = {
          method: "POST",
          body: data,
          header: myHeaders,
        };
        await Api.post(
          `/api/Plods/ID_${props.plod_id}/MiningCompany/ID_${props.miningCompanySelectorID}/Contract/ID_${props.contractSelectorID}/DrillingProgram/ID_${props.drillingProgramSelectorID}/Shift/ID_${props.shiftID}/~UpdateEntireShift`,
          dataJSON
        )
          .then((response) => {
            if (response.status == 400) {
              Swal.fire("failed to updated data.", "", "error");
            } else {
              Swal.fire("Successfully updated data.", "", "success");
            }
          })
          .catch((e) => Swal.fire(`An error occured.\n${e}`, "", "error"));
      } else {
        Swal.fire(result, "", "error");
      }
    }
  };

  return (
    <>
      <NewPlodBox color="rgb(95, 45, 135)" image={Operator} text="Operator">
        <DataGrid
          columns={operatorColumns}
          rows={operatorRows}
          editMode="row"
          getRowId={(row) => row.ID}
          rowModesModel={operatorRowModesModel}
          onRowModesModelChange={handleOperatorRowModesModelChange}
          processRowUpdate={processOperatorRowUpdate}
          disableRowSelectionOnClick
          slots={{
            toolbar: EditOperatorToolbar,
          }}
          slotProps={{
            toolbar: { setOperatorRows, setOperatorRowModesModel },
          }}
          sx={{
            minHeight: 300,
          }}
        />
      </NewPlodBox>

      <NewPlodBox
        color="rgb(242, 107, 49)"
        image={DrillingDetails}
        text="Drilling Details"
      >
        <DataGrid
          columns={drillingDetailsColumns}
          rows={drillingDetailsRows}
          editMode="row"
          getRowId={(row) => row.ID}
          rowModesModel={drillingDetailsRowModesModel}
          onRowModesModelChange={handleDrillingDetailsRowModesModelChange}
          processRowUpdate={processDrillingDetailsRowUpdate}
          disableRowSelectionOnClick
          slots={{
            toolbar: EditDrillingDetailsToolbar,
          }}
          slotProps={{
            toolbar: {
              setDrillingDetailsRows,
              setDrillingDetailsRowModesModel,
            },
          }}
          sx={{
            minHeight: 300,
          }}
        />
      </NewPlodBox>

      <NewPlodBox color="rgb(60, 124, 59)" image={Tasks} text="Tasks">
        <DataGrid
          columns={taskColumns}
          rows={taskRows}
          editMode="row"
          getRowId={(row) => row.ID}
          rowModesModel={taskRowModesModel}
          onRowModesModelChange={handleTaskRowModesModelChange}
          processRowUpdate={processTaskRowUpdate}
          disableRowSelectionOnClick
          slots={{
            toolbar: EditTaskToolbar,
          }}
          slotProps={{
            toolbar: { setTaskRows, setTaskRowModesModel },
          }}
          sx={{
            minHeight: 300,
          }}
        />
      </NewPlodBox>

      <NewPlodBox
        color="rgb(0, 85, 162)"
        image={Consumables}
        text="Consumables"
      >
        <DataGrid
          columns={consumableColumns}
          rows={consumableRows}
          editMode="row"
          getRowId={(row) => row.ID}
          rowModesModel={consumableRowModesModel}
          onRowModesModelChange={handleConsumableRowModesModelChange}
          processRowUpdate={processConsumableRowUpdate}
          disableRowSelectionOnClick
          slots={{
            toolbar: EditConsumableToolbar,
          }}
          slotProps={{
            toolbar: { setConsumableRows, setConsumableRowModesModel },
          }}
          sx={{
            minHeight: 300,
          }}
        />
      </NewPlodBox>

      <NewPlodBox
        color="rgb(23, 185, 100)"
        image={Consumables}
        text="One Off Charges"
      >
        <DataGrid
          columns={oneOffChargeColumns}
          rows={oneOffChargeRows}
          editMode="row"
          getRowId={(row) => row.ID}
          rowModesModel={oneOffChargeRowModesModel}
          onRowModesModelChange={handleOneOffChargeRowModesModelChange}
          processRowUpdate={processOneOffChargeRowUpdate}
          disableRowSelectionOnClick
          slots={{
            toolbar: EditOneOffChargeToolbar,
          }}
          slotProps={{
            toolbar: { setOneOffChargeRows, setOneOffChargeRowModesModel },
          }}
          sx={{
            minHeight: 300,
          }}
        />
      </NewPlodBox>


      <NewPlodBox
        color="rgb(10, 8, 8)"
        image={ShiftComments}
        text="Comments about the shift"
        height="30vh"
      >
        <TextareaAutosize
          style={{ width: "100%" }}
          value={shiftComments}
          onChange={(e) => setShiftComments(e.target.value)}
          minRows={5}
        />
      </NewPlodBox>

      <Button
        variant="contained"
        onClick={(e) => handleSubmitButtonClick(e)}
        style={{ marginLeft: 25, marginBottom: 50 }}
      >
        Submit
      </Button>
    </>
  );
};

// PlodDetails.propTypes = {
//     onShow: PropTypes.func.isRequired
// };

export default PlodDetails;
